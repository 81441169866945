@media screen and (max-width: 480px) {
    #feedback {
        display: none;
        font-size: "10px";
    }
    
    #correctAnswerText {
        display: none;
        margin-bottom: "0px"
    }
    
    #retryButton, #seeAnswerButton, #nextPuzzleButton {
        display: none;
        margin-top: 0px;
        margin-bottom: 10px;
        min-width: 150px;
    }
}

@media screen and (min-width: 720px) {
    #feedback {
        display: none;
        margin-bottom: "20px";
        font-size: "50px"
    }

    #correctAnswerText {
        display: none;
        margin-bottom: "20px";
        min-width: max-content;
    }

    #retryButton, #seeAnswerButton, #nextPuzzleButton {
        display: none;
        margin-top: 3px;
        margin-bottom: 12.5px;
        margin-right: 20px;
        min-width: 175px;
    }
}