@media screen and (max-width: 480px) {
    .chess-piece {
        height: 30px;
        width: 30px;
        z-index: 999;
    }

    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ffffff; /* Optional background color */
        display: none;
        z-index: 999;
    }

    .red-background {
        width: 38px;
        min-height: 38px;
        background-color: red;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 998;
        position: absolute;
    }
}

@media screen and (min-width: 720px) {
    .chess-piece {
        height: 55px;
        width: 55px;
        z-index: 999;
    }

    .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #ffffff; /* Optional background color */
        display: none;
        z-index: 999;
    }

    .red-background {
        width: 70px;
        min-height: 70px;
        background-color: red;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 998;
        position: absolute;
    }
}